.react-datepicker {
  @apply rounded border border-grey-8 bg-grey-1 p-3 shadow-[0_2px_8px_rgba(0,0,0,0.2),0_1px_3px_rgba(0,0,0,0.15),0_0_2px_rgba(0,0,0,0.25)];

  &__header {
    @apply border-b-0 bg-grey-1 p-0;
  }

  &__month {
    @apply m-0 p-0;
  }

  &__day-names {
    @apply m-0;
  }

  &__day-name,
  &__day {
    @apply h-6 w-6 !leading-[24px];
  }

  &__day-name {
    @apply m-0 h-6 w-8 text-10 font-bold text-grey-9;
  }

  &__day {
    @apply m-1 rounded-full text-12 font-normal text-grey-11;

    &:hover {
      @apply rounded-full bg-grey-4;
    }

    &--selected,
    &--keyboard-selected,
    &--in-range,
    &--in-selecting-range {
      @apply bg-blue-10 font-semibold text-grey-1;

      &:hover {
        @apply bg-blue-10;
      }
    }

    &--outside-month {
      @apply text-grey-8;
    }

    &--in-selecting-range:not(&--in-range) {
      @apply bg-blue-6 text-grey-1;
    }
  }
}
