.react-datepicker {

    border-radius: 0.25rem;

    border-width: 1px;

    border-color: var(--colors-grey8);

    background-color: var(--colors-grey1);

    padding: 0.75rem;

    --tw-shadow: 0 2px 8px rgba(0,0,0,0.2),0 1px 3px rgba(0,0,0,0.15),0 0 2px rgba(0,0,0,0.25);

    --tw-shadow-colored: 0 2px 8px var(--tw-shadow-color), 0 1px 3px var(--tw-shadow-color), 0 0 2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

  .react-datepicker__header {

    border-bottom-width: 0px;

    background-color: var(--colors-grey1);

    padding: 0px
}

  .react-datepicker__month {

    margin: 0px;

    padding: 0px
}

  .react-datepicker__day-names {

    margin: 0px
}

  .react-datepicker__day-name,
  .react-datepicker__day {

    height: 1.5rem;

    width: 1.5rem;

    line-height: 24px !important
}

  .react-datepicker__day-name {

    margin: 0px;

    height: 1.5rem;

    width: 2rem;

    font-size: 10px;

    line-height: 12px;

    letter-spacing: 0.01em;

    font-weight: 700;

    color: var(--colors-grey9)
}

  .react-datepicker__day {

    margin: 0.25rem;

    border-radius: 9999px;

    font-size: 12px;

    line-height: 1.5;

    letter-spacing: normal;

    font-weight: 400;

    color: var(--colors-grey11)
}

  .react-datepicker__day:hover {

    border-radius: 9999px;

    background-color: var(--colors-grey4)
}

  .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {

    background-color: var(--colors-blue10);

    font-weight: 600;

    color: var(--colors-grey1)
}

  .react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--in-range:hover, .react-datepicker__day--in-selecting-range:hover {

    background-color: var(--colors-blue10)
}

  .react-datepicker__day--outside-month {

    color: var(--colors-grey8)
}

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {

    background-color: var(--colors-blue6);

    color: var(--colors-grey1)
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78', system-ui, -apple-system, Roboto, Helvetica, Arial, sans-serif;font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78', system-ui, -apple-system, Roboto, Helvetica, Arial, sans-serif
}

